import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { MEDIA_QUERY } from '@zola/zola-ui/src/styles/emotion';

const scrolling = keyframes`
  0% { transform: translate3d(0, 0, 0); }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
`;

export const ImageContainer = styled.div<{
  inPreview?: 'DESKTOP' | 'MOBILE';
  h: number; // the intrinsic height of the image
  w: number; // the intrinsic width of the image
  containerHeight: number;
}>`
  height: 100vw;
  width: 100vw;
  ${MEDIA_QUERY.EXCLUDE_MOBILE} {
    height: ${p => p.containerHeight}px;
    width: ${p => {
      return (p.containerHeight * p.w) / p.h;
    }}px;
  }
`;

export const Image = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

export const MarqueeContainer = styled.div<{ inPreview?: 'DESKTOP' | 'MOBILE' }>`
  margin: 0;
  height: 100%;
  display: flex;
  overflow: hidden;
  min-width: 1px;
`;

export const ImageArrayContainer = styled.div<{ inPreview?: 'DESKTOP' | 'MOBILE' }>`
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 100%;
  width: fit-content;
  will-change: transform;
  backface-visibility: hidden;
  animation: ${scrolling} 30s linear infinite;
  ${MEDIA_QUERY.MOBILE} {
    animation: ${scrolling} 25s linear infinite;
  }
`;
