import React, { FC, useRef } from 'react';

// styles
import { PageAnimationWrapper } from 'components/publicWebsiteV2/common/PageAnimationWrapper/PageAnimationWrapper';
import {
  Image,
  ImageArrayContainer,
  MarqueeContainer,
  ImageContainer,
} from './WebsiteMarquee.styles';

export type MarqueeImage = {
  url: string;
  height: number;
  width: number;
};

export type WebsiteMarqueeProps = {
  marqueeImages: MarqueeImage[];
  inPreview?: 'DESKTOP' | 'MOBILE';
};

const WebsiteMarquee: FC<WebsiteMarqueeProps> = ({ inPreview, marqueeImages = [] }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const finalImages = marqueeImages;

  return (
    <MarqueeContainer inPreview={inPreview} ref={containerRef}>
      {containerRef.current &&
        containerRef.current?.clientHeight !== undefined &&
        finalImages.length > 0 && (
          <PageAnimationWrapper showOnPageLoad animationProps={{ isHero: true }}>
            <ImageArrayContainer inPreview={inPreview}>
              {finalImages.concat(finalImages).map((i, idx) => {
                return (
                  <ImageContainer
                    inPreview={inPreview}
                    h={inPreview === 'MOBILE' ? 1 : i.height} // Mobile images are all square
                    w={inPreview === 'MOBILE' ? 1 : i.width}
                    containerHeight={(containerRef.current as HTMLDivElement).clientHeight}
                    key={idx}
                  >
                    <Image src={i.url} alt="" />
                  </ImageContainer>
                );
              })}
            </ImageArrayContainer>
          </PageAnimationWrapper>
        )}
    </MarqueeContainer>
  );
};

export default WebsiteMarquee;
